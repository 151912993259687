@import './scss/_utility.scss';

// Colors
$background: #000;
$surface: rgba(255,255,255,0.05);
$text-default: #fff;
$text-subdued: rgba(255,255,255,0.5);
$border-default: rgba(255,255,255,0.2);
$accent: #3B7BFA;
$accent-2: #F4BB4E;

.surface {
  background-color: $surface;
}

.subdued {
  color: $text-subdued;
}

.accent {
  color: $accent;
}

.accent-2 {
  color: $accent-2;
}

.border {
  border: 1px solid $border-default;
  border-radius: 12px;
}

// Type
html {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;

  @media (max-width: 720px) {
    font-size: 14px;
    line-height: 22px;
  }
}

.text-display {
  font-weight: 600;
  font-size: 44px;
  line-height: 52px;
  letter-spacing: -0.2px;

  @media (max-width: 720px) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.2px;
  }
}

.text-body {
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 720px) {
    font-size: 14px;
    line-height: 22px;
  }
}

a {
  color: inherit;
  text-decoration: none;
  // border-bottom: .5px solid $text-subdued;

  &:hover {
    color: $text-default;
    border-bottom: .1px solid;
    transition-timing-function: ease-out;
    transition: 0.48s;
  }

  &:after {
    content: ' ↗'; /* show an arrow before the href */
    transition: transform 5s;
  }
}

// General
.App {
  color: $text-default;
  max-width: 100%;
}

.main-content {
  max-width: 640px;
  
  @media (max-width: 720px) 
  {
    margin: 0px 20px;
  }
}

.profile {
  height: 124px;

  @media (max-width: 720px) {
    display: none;
  }
}

.profile-mobile {
  height: 32px;
  
  @media (min-width: 720px) {
    display: none;
  }
}

.profile-header {
  height: 32px;
  border: 1x solid $border-default;
  border-radius: 8px;
}

.banner {
  margin-top: 120px;
  
  @media (max-width: 720px) {
    margin-top: 120px;
  }
}

.banner-bg {
  position: absolute;
  margin: auto;
  top: -200px;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("blur.png");

  z-index: -999;
  background-size: 100%;
  background-repeat: no-repeat;
  transition-timing-function: ease-in;
  transition: 3s;

  @media (min-width: 1920px) {
    background-size: 2000px;
  }
  
  @media (min-width: 1600px) {
    background-size: 100%;
  }

  @media (max-width: 1600px) {
    background-size: 150%;
  }

  @media (max-width: 1024px) {
    background-size: 200%;
    
  }

  @media (max-width: 720px) {
    background-size: 400%;
  }
}

.opacity {
  opacity: 1 !important;
}

@keyframes gradient {
	0% {
		opacity: 0.66;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.66;
	}
}

.footer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
}

.banner {
  display: flex;
  justify-content: space-between;
}

.banner-headline {
  display: flex;
  flex-direction: column;
}

.section {
  display: flex;
  flex-direction: column;
  
  .section-header {
    flex-direction: column;
  }
}

.social-links {
  display: flex;
  flex-direction: row;

  @media (max-width: 720px) {
    flex-direction: column;
  }
}

.social-link {
  margin-right: 16px;

  @media (max-width: 720px) {
    margin-top: 16px;
  }
}

.avatar-small {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  // border: 1px solid $border-default;
}

.comment {
  @media (max-width: 720px) {
    padding: 12px;
  }
}

@media (max-width: 720px) {
  .section {
    .section-header {
      flex-direction: column;
    }

    .section-item {
      flex-direction: column;

      .time {
        margin-bottom: 2px;
      }
    }
  }
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 48px;
  opacity: 0;
  background-color: $background;
  border-bottom: 1px solid $border-default;
  display: flex;
  align-items: center;
  transition-timing-function: ease-out;
  transition-duration: .2s;
  z-index: 999;
  
  @media (max-width: 720px) {
    margin: 0 auto 0 auto;
  }
  
  .header-content {
    width: 640px;
    margin: 0 auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 720px) {
      padding: 0px 20px;
    }
  }
}

// Utility
.w-120 {
  min-width: 120px;
}

// Fade-In Animation
@-webkit-keyframes fading {
  from {
    transform: translateY(24px);  
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;   
  }
}
@-moz-keyframes fading {
  from {
    transform: translateY(24px);  
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;   
  }
}

@keyframes fading {
  from {
    transform: translateY(24px);  
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;   
  }
}

.invisible {
  opacity: 0;
  -webkit-animation: fading ease-out .6s;
  animation: fading ease-out .6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.invisible.one {
  -webkit-animation-delay: .01s;
  animation-delay: .01s;
}

.invisible.two {
  -webkit-animation-delay: .1s;
  animation-delay: .1s;
}

.invisible.three {
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

.invisible.four {
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}

.invisible.five {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
}

.invisible.six {
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
}

.invisible.seven {
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}

.invisible.eight {
  -webkit-animation-delay: .7s;
  animation-delay: .7s;
}

.invisible.nine {
  -webkit-animation-delay: .8s;
  animation-delay: .8s;
}